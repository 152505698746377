import dynamic from "next/dynamic";
import { useEffect } from "react";
import Home from "../components/Layouts/Home";
import { Col, Container, Row } from "react-bootstrap";
import IndexFeatures from "../components/home/feature";
import { connect } from "react-redux";
import indexActions from "../redux/actions/indexActions";
const HomeBanner = dynamic(() => import("../components/home/banner"));
const HomeOffer = dynamic(() => import("../components/home/offer"));
const LargeCategory = dynamic(() =>
  import("../components/home/category").then((s) => s.LargeCategory)
);
const SmallCategory = dynamic(() =>
  import("../components/home/category").then((s) => s.SmallCategory)
);

const IndexPage = (props) => {
  const { loadData } = props;

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Home>
      <IndexFeatures />
      <Container className="home-container">
        <Row className="mb-5">
          <Col md="3">
            <HomeBanner type="ads" items={props.ads} />
          </Col>
          <Col md="9">
            <HomeBanner items={props.banner} type="banner" />
          </Col>
        </Row>
        <SmallCategory />
        <HomeOffer
          url={"/product/offer/DiscountHighToLow"}
          title="Grab The Discount"
          subtitle={"Hurry up these discount can get vanish next second"}
          redirect="/search?sort=DiscountHighToLow"
          text="View All Heavy Discount Products"
        />
        <LargeCategory />
        <HomeOffer
          url={"/product/offer/Latest"}
          title="Fresh In Town"
          subtitle={"Your Favorite seller might have something new for you"}
          redirect="/search?sort=Latest"
          text="View Fresh Arrivals Products"
        />
      </Container>
    </Home>
  );
};

// export async function getServerSideProps() {
//   // Fetch data from external API
//   try {
//     var date1 = new Date();

//     const res = await fetch(`https://dealstest.azurewebsites.net/`);
//     const data = await res.json();
//     var date2 = new Date();
//     // Pass data to the page via props
//     return {
//       props: {
//         serverData: data,
//         time: date2 - date1,
//       },
//     };
//   } catch {}
//   return {
//     props: {},
//   };
// }

// export async function getServerSideProps({ req }) {
//   const res = await axios.get("http://localhost:5000/api/auth", {
//     withCredentials: true,
//     headers: {
//       Cookie: req.headers.cookie,
//     },
//   });
//   const data = await res.data;
//   return { props: { data } };
// }

export default connect((s) => s.index, indexActions)(IndexPage);
