import { GET } from "../../utils/requests";
import { SET_INDEX_DATA } from "../types";

const indexActions = {
  loadData: () => async (dispatch) => {
    const data = await GET("/");
    dispatch({
      type: SET_INDEX_DATA,
      data,
    });
  },
};

export default indexActions;
